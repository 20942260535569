import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { rtdbPlugin } from "vuefire";
import { firestorePlugin } from "vuefire";
import locale from "element-ui/lib/locale/lang/es";

import splitPane from "vue-splitpane";

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

Vue.use(VueViewer);

Vue.component("split-pane", splitPane);

Vue.use(ElementUI, { locale });
Vue.use(firestorePlugin);
Vue.use(rtdbPlugin);

const firebaseConfig = {
  apiKey: "AIzaSyBMLwO-ThnzP-e07WUPcICIzXScl4lxuLo",
  authDomain: "arca-tour-pus.firebaseapp.com",
  databaseURL: "https://arca-tour-pus-default-rtdb.firebaseio.com",
  projectId: "arca-tour-pus",
  storageBucket: "arca-tour-pus.appspot.com",
  messagingSenderId: "877603247521",
  appId: "1:877603247521:web:b4296405b5ef3de10fe7e0",
  measurementId: "G-1LNNHF87TS",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

export const bus = new Vue();

import Vue from "vue";
import VueRouter from "vue-router";
import getCurrentUser from "./auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/presentacion",
    name: "Presentacion",
    component: () =>
      import(/* webpackChunkName: "presentacion" */ "../views/presentacion"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "presentacion" */ "../views/admin"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  var user = await getCurrentUser();
  if (requiresAuth && !user) {
    next("");
  } else {
    if (requiresAdmin) {
      var idTokenResult = await user.getIdTokenResult();
      console.log(idTokenResult.claims);
      if (!idTokenResult.claims.admin) {
        next("");
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
